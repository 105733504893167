<template>

  <div class="row" ref="redirectionForm">

    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <div class="card card-wizard" id="wizardCard">
          <form-wizard shape="tab"
                       :title="formTitle"
                       :subtitle="''"
                       error-color="#FB404B"
                       color="#35495E">
            <tab-content title="English Content"
                         class="col-12"
                         icon="nc-icon nc-single-copy-04"
                         :before-change="() => validateStep('englishStep')">
              <english-step :formData="formData ? formData['en'] : defaultFormData['en']"
                            :builderData="builderData"
                            :editMode="editMode"
                            ref="englishStep"
                            @on-validated="onStepValidated" @submit-step="submitStep"></english-step>
            </tab-content>
            <tab-content v-for="(lang, index) in languageList" :key="index" :title="lang.label+' Content'"
                         class="col-12"
                         icon="nc-icon nc-single-copy-04"
                         :before-change="() => validateStep(lang.value)">
              <other-languages-step
                :formData="formData ? formData[lang.value] : defaultFormData[lang.value]"
                :editMode="editMode"
                :isSubmit="languageList.length - 1 === index"
                :lang="lang" :ref="lang.value" @on-validated="onStepValidated"
                @submit-step="submitStep"></other-languages-step>
            </tab-content>

            <button slot="prev" class="btn btn-default btn-fill btn-wd btn-back">Back</button>
            <button slot="next" class="btn btn-default btn-fill btn-wd btn-next">Next</button>
            <button slot="finish" class="btn btn-success btn-fill btn-wd" :disabled="entityNotFoundError || submitting">Finish</button>
          </form-wizard>
        </div>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {mapGetters} from "vuex";
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import EnglishStep from "./RedirectionPageWizard/EnglishStep";
import OtherLanguagesStep from "./RedirectionPageWizard/OtherLanguagesStep";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormWizard,
    TabContent,
    OtherLanguagesStep,
    EnglishStep,
  },
  computed: {
    ...mapGetters({
      languageList: "global/languageList"
    })
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {},
      defaultFormData: {
        page_name: "",
        parent_id: "",
        redirection_type: "",
        redirection_url: "",
        redirection_target: "",
        is_active: true,
      },
      builderData: {
        pageList: [],
        redirectionTypes: [],
        redirectionTargets: [],
      },
      wizardModel: {}
    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.redirectionForm
    });

    let data = {}
    this.axios.post("redirection-pages/builder", data).then((response) => {

      this.builderData.redirectionTypes = response.data.redirectionTypes;
      this.builderData.redirectionTargets = response.data.redirectionTargets;
      this.builderData.pageList = response.data.pageList;
      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Redirection Page";
        this.getPage();
      } else {
        this.editMode = false;
        this.formTitle = "Add Redirection Page";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getPage() {
      let data = {
        'id': this.id,
      };
      this.axios.post("redirection-pages/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Page Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("redirection-pages/update/" + this.id, this.formData);
        successMessage = "Page Updated Successfully";
      } else {
        request = this.axios.post("redirection-pages/create", this.formData);
        successMessage = "Page Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/pages/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    validateStep(ref) {
      if (Array.isArray(this.$refs[ref])){
        return this.$refs[ref][0].validate();
      }else{
        return this.$refs[ref].validate();
      }
    },
    onStepValidated(validated, model) {
      this.wizardModel = {...this.wizardModel, ...model}
    },
    submitStep(data, lang, isSubmit){
      this.formData[lang] = data;
      if (isSubmit){
        this.submit();
      }
    },

  }
}
</script>
