var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v("English Section")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"vid":"en.parent_id","rules":"required","name":"The Parent"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"parent_id","size":"large","filterable":"","clearable":"","placeholder":"Parent","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Parent',"list":_vm.builderData.pageList,"listItemLabel":'label',"listItemValue":'id'},model:{value:(_vm.formData.parent_id),callback:function ($$v) {_vm.$set(_vm.formData, "parent_id", $$v)},expression:"formData.parent_id"}})]}}])}),_c('ValidationProvider',{attrs:{"vid":"en.page_name","rules":"required","name":"The Page Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Page Name","name":"page_name","fou":""},model:{value:(_vm.formData.page_name),callback:function ($$v) {_vm.$set(_vm.formData, "page_name", $$v)},expression:"formData.page_name"}})]}}])}),_c('ValidationProvider',{attrs:{"vid":"en.redirection_type","rules":"required","name":"The Redirection Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"redirection_type","size":"large","filterable":"","clearable":"","placeholder":"Redirection Type","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Redirection Type',"list":_vm.builderData.redirectionTypes,"listItemLabel":'label',"listItemValue":'value'},model:{value:(_vm.formData.redirection_type),callback:function ($$v) {_vm.$set(_vm.formData, "redirection_type", $$v)},expression:"formData.redirection_type"}})]}}])}),_c('ValidationProvider',{attrs:{"vid":"en.redirection_url","rules":"required","name":"The Redirection Url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Redirection Url","name":"redirection_url","fou":""},model:{value:(_vm.formData.redirection_url),callback:function ($$v) {_vm.$set(_vm.formData, "redirection_url", $$v)},expression:"formData.redirection_url"}})]}}])}),_c('ValidationProvider',{attrs:{"vid":"en.redirection_target","rules":"required","name":"The Redirection Target"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"redirection_target","size":"large","filterable":"","clearable":"","placeholder":"Redirection Type","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Redirection Target',"list":_vm.builderData.redirectionTargets,"listItemLabel":'label',"listItemValue":'value'},model:{value:(_vm.formData.redirection_target),callback:function ($$v) {_vm.$set(_vm.formData, "redirection_target", $$v)},expression:"formData.redirection_target"}})]}}])}),_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v("Is Active")]),_vm._v(" "),_c('l-switch',{model:{value:(_vm.formData.is_active),callback:function ($$v) {_vm.$set(_vm.formData, "is_active", $$v)},expression:"formData.is_active"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }